:root {
    --ion-toolbar-background: var(--ion-color-dark);
    --ion-toolbar-color: var(--ion-color-dark-contrast);
    --ion-toolbar-border-color: var(--ion-color-dark-contrast);
    --ion-toolbar-badge-color: #fda401;
    --app-top-navbar-height: 75px;

    --side-menu-background-color: rgba(var(--ion-color-dark-rgb), 0.7);
    --side-menu-text-color: var(--ion-color-dark-contrast);
    --side-menu-text-color-rgb: var(--ion-color-dark-contrast-rgb);
    --side-menu-highlight-color: var(--ion-color-tertiary);

    --footer-bottom-background: var(--ion-color-dark);
}